import TagManager from 'react-gtm-module';
import { DateTime } from 'luxon';
import { RoutePath } from '@hooks/useAppRouter';
import postPageViewEvent from '@src/apis/statisticsApi';

const staticPageViewEvent: Partial<{ [Key in RoutePath]: string }> = {
  '/': 'Page_View_Ranking', // 화면조회_네컷랭킹
  '/ranking': 'Page_View_Feed', // 화면조회_실시간랭킹
  '/home': 'Page_View_Home', // 화면조회_홈
  '/home/reward': 'Page_View_Reward', // 화면조회_포토그레이리워드
  '/home/history/stamp': 'Page_View_Stamp', // 화면조회_스탬프내역
  '/home/history/coupon': 'Page_View_Coupon', // 화면조회_쿠폰내역
  '/profile/me': 'Page_View_MyRanking', // 화면조회_MY랭킹
  '/sign-up/step1': 'Page_View_Signup_Agreement', // 회원가입_약관동의
  '/sign-up/step2': 'Page_View_Signup_Nickname', // 회원가입_닉네임설정
  '/sign-up/step3': 'Page_View_Signup_Region', // 회원가입_지역정보
  '/start': 'Page_View_Signup_Start', // 회원가입_시작하기
  '/story': 'Page_View_Stories', // 화면조회_NewStory
  '/setting': 'Page_View_Setting', // 화면조회_환경설정
  '/setting/help': 'Page_View_Help', // 화면조회_고객센터
  '/push-history': 'Page_View_PushHistory', // 화면조회_알림
  '/notice': 'Page_View_Notice', // 화면조회_공지사항
  '/space': 'Page_View_SpaceGame_Home', // 화면조회_우주게임홈
  '/space/game': 'Page_View_SpaceGame_Play', // 화면조회_우주게임플레이
  '/4cut-ranking/apply/category': 'Page_View_RegisterCard_Category', // 화면조회_포토카드등록중_성별
  '/4cut-ranking/apply/photo-card-name': 'Page_View_RegisterCard_PhotoCardName', // 화면조회_포토카드등록중_이름
  '/4cut-ranking/apply/photo': 'Page_View_RegisterCard_PhotoUpload', // 화면조회_포토카드등록중_사진등록
};

export const customTagManager = {
  staticPageView: (path: RoutePath): void => {
    const pageViewEvent = staticPageViewEvent[path];
    if (pageViewEvent) {
      TagManager.dataLayer({
        dataLayer: {
          event: pageViewEvent,
        },
      });

      postPageViewEvent
        .postPageViewEvent({
          pageId: pageViewEvent,
          eventDateTime: DateTime.now().toString(),
        })
        .then();
    }
  },
  pageViewStory: (params: { title: string }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Page_View_Story',
        ...params,
      },
    });
  },
  pageViewUserProfile: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Page_View_Profile',
      },
    });
  },
  loginApple: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Login_Apple',
      },
    });
  },
  loginKakao: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Login_Kakao',
      },
    });
  },
  signInSnsSignedIn: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SignIn_SNS_SignedIn',
      },
    });
  },
  signUpMembershipCompleted: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SignUp_Membership_Completed',
      },
    });
  },
  registerCardCompleted: (params: {
    category: string;
    memberType: string;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Register_Card_Completed',
        ...params,
      },
    });
  },
  registerCardCardUrlCopied: (params: {
    category: string;
    photoCardName: string;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'RegisterCard_Card_UrlCopied',
        ...params,
      },
    });
  },
  gameSelectCategory: (params: { category: string }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_SelectCategory',
        ...params,
      },
    });
  },
  gameEnterTournament: (params: {
    category: string;
    memberType: string;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_Enter_Tournament',
        ...params,
      },
    });
  },
  gameEnterQuarterFinal: (params: {
    category: string;
    memberType: string;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_Enter_QuarterFinal',
        ...params,
      },
    });
  },
  gameEnterSemiFinal: (params: {
    category: string;
    memberType: string;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_Enter_SemiFinal',
        ...params,
      },
    });
  },
  gameEnterFinal: (params: { category: string; memberType: string }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_Enter_Final',
        ...params,
      },
    });
  },
  gamePickTournament: (params: {
    category: string;
    memberType: string;
    picked: string;
    round: number;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_Pick_Tournament',
        ...params,
      },
    });
  },
  gamePickQuarterFinal: (params: {
    category: string;
    memberType: string;
    picked: string;
    round: number;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_Pick_QuarterFinal',
        ...params,
      },
    });
  },
  gamePickSemiFinal: (params: {
    category: string;
    memberType: string;
    picked: string;
    round: number;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_Pick_SemiFinal',
        ...params,
      },
    });
  },
  gamePickFinal: (params: {
    category: string;
    memberType: string;
    picked: string;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_Pick_Final',
        ...params,
      },
    });
  },
  gameFinished: (params: { category: string; photoCardName: string }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_Finished',
        ...params,
      },
    });
  },
  gameResultCardDetailCheckedTrophy: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_ResultCardDetail_CheckedTrophy',
      },
    });
  },
  gameResultCardDetailClickedSns: (params: { snsType?: string }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_ResultCardDetail_ClickedSns',
        ...params,
      },
    });
  },
  gameResultCardDetailTapHotCards: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Game_ResultCardDetail_TapHotCards',
      },
    });
  },
  feedListMoreClicked: (params: { subjectNo?: number }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Feed_List_MoreClicked',
        ...params,
      },
    });
  },
  feedHotCardsClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Feed_HotCards_Clicked',
      },
    });
  },
  feedPhotocardDetail: (params: {
    category: string;
    photoCardName: string;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Feed_Photocard_Detail',
        ...params,
      },
    });
  },
  feedFeedCardDetailCheckedTrophy: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Feed_FeedCardDetail_CheckedTrophy',
      },
    });
  },
  feedSnsClicked: (params: {
    snsType?: string;
    category?: string;
    photoCardName?: string;
  }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Feed_SNS_Clicked',
        ...params,
      },
    });
  },
  feedSearchFieldKeyword: (params: { searchKeyword: string }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Feed_SearchField_Keyword',
        ...params,
      },
    });
  },
  myPageSnsAdded: (params: { snsType: string }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'MyPage_SNS_Added',
        ...params,
      },
    });
  },
  myRankingProfileEditClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'MyRanking_Profile_Edit_Clicked',
      },
    });
  },
  myRankingSnsPopupClicked: (params: { snsType: string }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'MyRanking_SNS_Popup_Clicked',
        ...params,
      },
    });
  },
  myRankingSnsPopupHideClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'MyRanking_SNS_Popup_Hide_Clicked',
      },
    });
  },
  signupCertificateStart: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Signup_Certificate_Start',
      },
    });
  },
  signupCertificateEnd: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Signup_Certificate_End',
      },
    });
  },
  homeCouponClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Home_Coupon_Clicked',
      },
    });
  },
  homeSpaceGameBannerClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Home_SpaceGame_Banner_Clicked',
      },
    });
  },
  homeSpaceGamePopupClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Home_SpaceGame_Popup_Clicked',
      },
    });
  },
  homeStoriesClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Home_Stories_Clicked',
      },
    });
  },
  homeStoryClicked: (params: { title: string }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Home_Story_Clicked',
        ...params,
      },
    });
  },
  homePhotoCardsClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Home_PhotoCards_Clicked',
      },
    });
  },
  accountCertificateClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Account_Certificate_Clicked',
      },
    });
  },
  accountWithdraw: (params: { cause: string }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Account_Withdraw',
        ...params,
      },
    });
  },
  couponBranchClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Coupon_Branch_Clicked',
      },
    });
  },
  couponBarcodeClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Coupon_Barcode_Clicked',
      },
    });
  },
  couponBarcodeZoomed: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Coupon_Barcode_Zoomed',
      },
    });
  },
  qrPopupView: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'QR_Popup_View',
      },
    });
  },
  helpKakaoClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Help_Kakao_Clicked',
      },
    });
  },
  helpPhoneClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Help_Phone_Clicked',
      },
    });
  },
  helpInquiryClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Help_Inquiry_Clicked',
      },
    });
  },
  helpCouponClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Help_Coupon_Clicked',
      },
    });
  },
  helpHelpClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Help_Help_Clicked',
      },
    });
  },
  spaceGameTutorialPopupView: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_Tutorial_Popup_View',
      },
    });
  },
  spaceGameRankingButtonClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_RankingButton_Clicked',
      },
    });
  },
  spaceGameShopButtonClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_ShopButton_Clicked',
      },
    });
  },
  spaceGameMissionButtonClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_MissionButton_Clicked',
      },
    });
  },
  spaceGameDrawStatusButtonClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_DrawStatusButton_Clicked',
      },
    });
  },
  spaceGameStart: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_Start',
      },
    });
  },
  spaceGameGiveUp: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_GiveUp',
      },
    });
  },
  spaceGameEnd: (params: { lastStage: number }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_End',
        ...params,
      },
    });
  },
  spaceGameApplied: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_Applied',
      },
    });
  },
  spaceGameMissionAMClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_Mission_AM_Clicked',
      },
    });
  },
  spaceGameMissionPMClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_Mission_PM_Clicked',
      },
    });
  },
  spaceGameMissionRankingClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_Mission_Ranking_Clicked',
      },
    });
  },
  spaceGameMissionShareClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_Mission_Share_Clicked',
      },
    });
  },
  spaceGameMissionStampClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'SpaceGame_Mission_Stamp_Clicked',
      },
    });
  },
  signupRegionSubmit: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Signup_Region_Submit',
      },
    });
  },
  signupRegionExit: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Signup_Region_Exit',
      },
    });
  },
  profileEditRegionView: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'ProfileEdit_Region_View',
      },
    });
  },
  profileEditRegionSubmit: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'ProfileEdit_Region_Submit',
      },
    });
  },
  profileEditRegionExit: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'ProfileEdit_Region_Exit',
      },
    });
  },
  bottomSheetRegionView: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Region_BottomSheet_View',
      },
    });
  },
  bottomSheetRegionSubmit: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Region_BottomSheet_Submit',
      },
    });
  },
  bottomSheetRegionExit: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Region_BottomSheet_Exit',
      },
    });
  },
  registerCardClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Register_Card_Clicked',
      },
    });
  },
  registerCardCategorySubmit: (params: { category: CategoryType }): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'RegisterCard_Category_Submit',
        ...params,
      },
    });
  },
  profileInstagramClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Profile_Instagram_Clicked',
      },
    });
  },
  profileTiktokClicked: (): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Profile_Tiktok_Clicked',
      },
    });
  },
};
